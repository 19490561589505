@font-face {
  font-family: 'museo-sans';
  src: url('../assets/fonts/museo-sans900.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../assets/fonts/museo-sans700.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../assets/fonts/museo-sans500.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../assets/fonts/museo-sans300.ttf') format('truetype');
  font-weight: 300;
}

/* Web */
@media only screen and (min-width: 768px) {
  .loader-container {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    background-color: #000000aa;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .spinner {
      color: #DADADA;
      width: 4vw !important;
      height: 4vw !important;
    }
  }
}

@import './font_clases/web_classes.scss';
@import './font_clases/tablet_classes.scss';
@import './font_clases/mobile_classes.scss';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 68px;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-width: 350px;
}

p {
  margin: 0;
  white-space: pre-wrap;
  cursor: default;
}

/* width */
::-webkit-scrollbar {
  width: .4vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E5E5E5;
  border-radius: .5vw;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  border-radius: .5vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

.App {
  $max-width: 1024px;
  $horizantal-padding: 20px;
  background-color: #F9F9F9;

  .hamburger-menu {
    min-width: 350px;
    position: fixed;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFFF2;
    display: flex;
    justify-content: center;
    align-items: center;

    > .close {
      position: absolute;
      top: 30px;
      right: 30px;

      > svg {
        width: 20px;
        height: 20px;

        path {
          fill: #4577FB;
        }
      }
    }

    > .links {
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: center;

      a {
        @include L3-mobile;
        color: #4577FB;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .popup-main {
    min-width: 350px;
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background-color: #000000B2;
    display: flex;
    justify-content: center;
    align-items: center;

    > .container {
      position: relative;
      background-color: #FFFFFF;
      border: 0;
      border-radius: 20px;
      padding: 50px 0;
      width: clamp(350px, 50vw, 512px);

      > .close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 20px;
        height: 20px;
  
        > svg {
          path {
            fill: #4577FB;
          }
        }
      }

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;

        > .title {
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
        }

        > .body {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }

        > .button {
          background-color: #4577FB;
          color: #F9F9F9;
          border: 0;
          border-radius: 30px;
          padding: 8px 40px;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }

  .navbar {
    position: fixed;
    background-color: #FFFFFF;
    width: calc(100% - (2 * $horizantal-padding));
    display: flex;
    z-index: 10;
    padding: 10px $horizantal-padding;

    .navbar-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $max-width;
      margin: 0 auto;

      .logo {
        height: 48px;

        svg {
          height: 100%;

          path {
            fill: #4577FB;
          }
        }
      }

      .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        a {
          @include L1-web;
          color: #1B1B1B;
          cursor: pointer;
          height: 48px;
          padding: 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .menu {
        display: none;
      }
    }
  }

  .landing-content {
    width: 100%;
    
    .top-container {
      background: linear-gradient(180deg, #F9F9F9 10%, rgba(69, 119, 251, 0.3) 100%);
      padding: 160px $horizantal-padding 40px;
      display: flex;
      flex-direction: column;
      gap: 50px;

      > .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        text-align: center;
        max-width: $max-width;
        margin: 0 auto;

        > .title {
          @include T1-web;
        }

        > .text {
          @include S1-web;
          max-width: 670px;
        }

        > .contact-button {
          @include B-web;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 45px;
          border: 0;
          border-radius: 30px;
          background: #4577FB;
          color: #F9F9F9;
          text-decoration: none;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      > .image-container {
        max-width: $max-width;
        margin: 0 auto;
        padding: 15px 0;

        img {
          width: 100%;
        }
      }
    }

    #conocenos {
      background: linear-gradient(180deg, rgba(69, 119, 251, 0.3) 0%, rgba(249, 249, 249, 0.1) 100%);
      padding: 40px $horizantal-padding 80px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        text-align: center;
        max-width: $max-width;
        margin: 0 auto;
        padding: 30px 0;

        > .title {
          @include T1-web;
        }

        > .text {
          @include S1-web;
        }
      }

      > .image-container {
        max-width: $max-width;
        margin: 0 auto;

        img {
          width: 100%;
          border: 0;
          border-radius: 32px;
        }
      }
    }

    #beneficios {
      padding: 50px $horizantal-padding 100px;
      max-width: $max-width;
      margin: 0 auto;
      display: flex;
      gap: 20px;

      .left {
        width: 400px;

        p {
          @include T1-web;
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .bubble {
          border: 0;
          border-radius: 32px;
          padding: 50px 24px;
          display: flex;
          align-items: center;
          gap: 24px;
          box-shadow: 2px 5px 8px 0px #0000001F;

          &-blue {
            background-color: #4577FB;
            color: #FFFFFF;

            .title {
              border: 2px solid #FFFFFF;
            }

            .icon {
              background-color: #FFFFFF;

              path {
                fill: #4577FB;
              }
            }
          }

          &-white {
            background-color: #FFFFFF;
            color: #4577FB;

            .title {
              border: 2px solid #4577FB;
            }

            .icon {
              background-color: #4577FB;

              path {
                fill: #FFFFFF;
              }
            }
          }

          .title {
            width: 40px;
            height: 150px;
            border-radius: 40px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              @include S1-web;
              transform: rotate(-90deg);
            }
          }

          .text {
            flex: 1;

            ul {
              margin: 0;
              padding-inline-start: 20px;
              
              li {
                @include S1-web;
              }
            }
          }

          .icon {
            width: 140px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            border-radius: 140px;

            svg {
              width: 80px;
            }
          }
        }
      }
    }

    #dashboard {
      background-color: #4577FB;
      color: #FFFFFF;
      padding: 50px $horizantal-padding;

      > .container {
        max-width: $max-width;
        margin: 0 auto;

        > .title {
          padding: 30px 0;
          
          p {
            @include T1-web;
            text-align: center;
          }
        }

        > .text {
          width: 100%;
          height: 600px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          > .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > .title {
              p {
                @include T2-web;
              }
            }

            > .text {
              p {
                @include S1-web;
              }
            }
          }

          > .right {
            width: 510px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              position: absolute;
              width: 405px;
              height: 405px;
              background-color: #F9F9F94D;
              border: 0;
              border-radius: 405px;
            }

            img {
              width: 100%;
              z-index: 1;
            }
          }
        }
      }

    }

    #clientes {
      max-width: $max-width;
      margin: 0 auto;
      padding: 80px $horizantal-padding 50px;

      > .text {
        text-align: center;

        > .title {
          @include T1-web;
          padding: 30px 0 20px;
        }

        > .subtitle {
          @include S1-web;
          padding: 0 0 10px;          
        }
      }

      > .phones-container {
        position: relative;
        padding: 50px 0;
        display: flex;
        justify-content: space-evenly;

        > span {
          position: absolute;
          top: 225px;
          left: 0;
          width: 100%;
          height: 90px;
          border: 0;
          border-radius: 90px;
          background-color: #4577FB;
          opacity: 0.3;
        }

        > .phone-container {
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          width: 220px;

          > img {
            width: 100%;
          }
          
          > .number {
            @include T3-web;
            background-color: #4577FB;
            color: #FFFFFF;
            border: 0;
            border-radius: 50px;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text {
            @include B-web;
            color: #4577FB;
            text-align: center;
          }
        }
      }
    }

    #faqs {
      max-width: $max-width;
      margin: 0 auto;
      padding: 40px $horizantal-padding 60px;
      display: flex;

      > .left {
        width: 400px;

        > .title {
          @include T1-web;
        }
      }

      > .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 15px;

        > .section {
          background-color: #FFFFFF;
          border: 1px solid #CBD6E2;
          border-radius: 16px;
          padding: 10px 28px;

          > .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            height: 48px;
            cursor: pointer;

            > p {
              @include S1-web;
              cursor: pointer;
            }

            > svg {
              width: 16px;
              height: 8px;
              transition: transform 0.5s;

              &[data-opened="true"] {
                transform: rotate(180deg);
              }

              path {
                fill: #00000066;
              }
            }
          }

          > .text {
            padding: 0 15px;
            overflow: hidden;
            max-height: 250px;
            transition: max-height 0.5s ease-in;

            &[data-opened="false"] {
              max-height: 0;
              transition: max-height 0.5s ease-out;
            }

            > p {
              @include S3-web;
              padding: 10px 0 15px;
              color: #00000066;
            }
          }
        }

        > .question {
          @include S2-web;
          text-align: right;
          color: #00000099;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    #contacto {
      max-width: $max-width;
      margin: 0 auto;
      padding: 40px $horizantal-padding 100px;

      > .container {
        position: relative;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        padding: 0 60px;
        border: 0;
        border-radius: 16px;
        box-shadow: 0px 0px 12px 0px #00000014;
        height: 530px;
        gap: 60px;

        > .isotype {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 60px;
          height: 60px;

          path {
            fill: #4577FB;
          }
        }

        > .left {
          width: 380px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          > .title {
            @include T1-web;
          }

          > .subtitle {
            @include S1-web;
            color: #00000099;
          }
        }

        > .right {
          flex: 1;

          > form {
            display: flex;
            flex-direction: column;
            gap: 18px;

            > label {
              @include S2-web;
              display: flex;
              flex-direction: column;
              gap: 5px;

              > input,
              > textarea {
                @include S3-web;
                height: 38px;
                border: 1px solid #CBD6E2;
                border-radius: 10px;
                padding: 0 10px;
                background-color: #F9F9F9;
              }

              > textarea {
                height: 67px;
                padding: 10px;
                resize: none;

                &::placeholder {
                  font-style: italic;
                  color: #00000066;
                }
              }
            }

            > button {
              @include B-web;
              width: fit-content;
              padding: 5px 40px;
              margin-left: auto;
              border: 0;
              border-radius: 30px;
              background-color: #4577FB;
              color: #F9F9F9;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .footer {
    background-color: #4577FB;
    color: #F9F9F9;
    padding: 50px 0 20px;

    > .content {
      max-width: $max-width;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 45px;
      padding: 0 $horizantal-padding;
      margin-bottom: 45px;

      > .logo {
        height: 50px;
        width: fit-content;

        path {
          fill: #F9F9F9;
        }
      }

      > .info {
        display: flex;
        justify-content: space-between;

        > .left {
          display: flex;

          > .container {
            width: 200px;
            display: flex;
            flex-direction: column;
            gap: 17px;

            > .title {
              @include S1-web;
            }

            > .item {
              @include L2-web;
              color: #F9F9F9;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        > .right {
          display: flex;
          flex-direction: column;
          gap: 17px;
          text-align: right;

          > .title {
            @include S1-web;
          }

          > .link {
            color: #F9F9F9;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;

            &:hover {
              text-decoration: underline;
            }

            > svg {
              width: 22px;
              height: 22px;

              path {
                fill: #F9F9F9;
              }
            }

            > p {
              @include L2-web;
              cursor: pointer;
            }
          }
        }
      }
    }

    > .copyright {
      border-top: 1px solid #F9F9F980;

      > p {
        @include L3-web;
        padding-top: 20px;
        text-align: center;          
      }
    }
  }
}

/* Tablet */
@media only screen and (max-width: 989px) {
  .App {
    .navbar {
      .navbar-content {
        .links {
          a {
            @include L1-tablet;
          }
        }
      }
    }
  
    .landing-content {
      .top-container {
        > .text-container {
          > .title {
            @include T1-tablet;
          }
  
          > .text {
            @include S2-tablet;
          }
  
          > .contact-button {
            @include B-tablet;
          }
        }
      }
  
      #conocenos {
        > .text-container {
          > .title {
            @include T1-tablet;
          }
  
          > .text {
            @include S2-tablet;
          }
        }
      }
  
      #beneficios {
        .left {
          width: 300px;

          p {
            @include T1-tablet;
          }
        }
  
        .right {
          .bubble {
            .title {
              p {
                @include S2-tablet;
              }
            }
  
            .text {
              ul {
                li {
                  @include S2-tablet;
                }
              }
            }
          }
        }
      }
  
      #dashboard {
        > .container {
          > .title {
            p {
              @include T1-tablet;
            }
          }
  
          > .text {
            > .left {
              > .title {
                p {
                  @include T2-tablet;
                }
              }
  
              > .text {
                p {
                  @include S2-tablet;
                }
              }
            }

            > .right {
              width: auto;
              max-width: 410px;

              > span {
                width: 350px;
                height: 350px;
              }
            }
          }
        }
  
      }
  
      #clientes {
        > .text {
          > .title {
            @include T1-tablet;
          }
  
          > .subtitle {
            @include S2-tablet;        
          }
        }
  
        > .phones-container {
          > .phone-container {
            > .number {
              @include T3-tablet;
            }
  
            .text {
              @include B-tablet;
            }
          }
        }
      }
  
      #faqs {
        flex-direction: column;
    
        > .left {
          width: auto;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;

          > .title {
            @include T1-tablet;
            white-space: normal;
          }
        }
  
        > .right {
          > .section {
            > .title {  
              > p {
                @include S1-tablet;
              }
            }
  
            > .text {
              > p {
                @include S3-tablet;
              }
            }
          }
  
          > .question {
            @include S2-tablet;
          }
        }
      }
  
      #contacto {
        > .container {
          > .left {
            width: 320px;

            > .title {
              @include T1-tablet;
            }
  
            > .subtitle {
              @include S2-tablet;
            }
          }
  
          > .right {
            > form {
              > label {
                @include S2-tablet;

                > input,
                > textarea {
                  @include S3-tablet;
                }
              }
  
              > button {
                @include B-tablet;
              }
            }
          }
        }
      }
    }
  
    .footer {
      > .content {
        > .info {
          > .left {
            > .container {
              > .title {
                @include S2-tablet;
              }
  
              > .item {
                @include L1-tablet;
              }
            }
          }
  
          > .right {
            > .title {
              @include S2-tablet;
            }
  
            > .link {
              > svg {
                width: 18px;
                height: 18px;
              }

              > p {
                @include L1-tablet;
              }
            }
          }
        }
      }
  
      > .copyright {
        > p {
          @include L2-tablet;         
        }
      }
    }
  }
}

/* Mobile landscape */
@media only screen and (max-width: 767px) {
  .App {
    .navbar {
      .navbar-content {
        .links {
          display: none;
        }

        .menu {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: 0;
          padding: 0;
          cursor: pointer;

          svg {
            width: 36px;
            height: 36px;

            path {
              fill: #4577FB;
            }
          }
        }
      }
    }

    .landing-content {
      #beneficios {
        flex-direction: column;
  
        > .left {
          width: 100%;
          text-align: center;
        }

        > .right {
          > .bubble {
            padding: 40px 10px;
            flex-direction: column;

            > .title {
              width: 130px;
              height: 35px;

              > p {
                transform: none;
              }
            }

            > .text {
              > ul {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }

      #dashboard {
        > .container {
          > .text {
            flex-direction: column;
            height: auto;

            > .right {
              height: 430px;
            }
          }
        }
      }

      #clientes {
        > .phones-container {
          padding: 100px 0 0;
          flex-direction: column;
          align-items: center;
          gap: 80px;

          > span {
            top: 50px;
            left: auto;
            width: 125px;
            height: 1925px;
          }

          > .phone-container {
            width: auto;

            > img {
              width: 220px;
            }
          }
        }
      }

      #faqs {
        > .right {
          > .section {
            > .text {
              max-height: 305px;
            }
          }
        }
      }

      #contacto {
        > .container {
          flex-direction: column;
          height: auto;
          padding: 100px 20px 40px;
          align-items: normal;
          gap: 25px;

          > .left {
            width: auto;
          }
        }
      }
    }

    .footer {
      > .content {
        > .info {
          > .left {
            flex-direction: column;
            gap: 50px;
          }
        }
      }
    }
  }
}

/* Mobile portrait */
@media only screen and (max-width: 477px) {
  .App {  
    .landing-content {
      .top-container {
        > .text-container {
          > .title {
            @include T1-mobile;
          }
  
          > .text {
            @include S2-mobile;
          }
  
          > .contact-button {
            @include B-mobile;
          }
        }
      }
  
      #conocenos {
        > .text-container {
          > .title {
            @include T1-mobile;
          }
  
          > .text {
            @include S2-mobile;
          }
        }
      }
  
      #beneficios {
        .left {
          p {
            @include T1-mobile;
          }
        }
  
        .right {
          .bubble {
            .title {
              p {
                @include S2-mobile;
              }
            }
  
            .text {
              ul {
                li {
                  @include S2-mobile;
                }
              }
            }
          }
        }
      }
  
      #dashboard {
        > .container {
          > .title {
            p {
              @include T1-mobile;
            }
          }
  
          > .text {
            > .left {
              > .title {
                p {
                  @include T2-mobile;
                }
              }
  
              > .text {
                p {
                  @include S2-mobile;
                }
              }
            }
          }
        }
  
      }
  
      #clientes {
        > .text {
          > .title {
            @include T1-mobile;
          }
  
          > .subtitle {
            @include S2-mobile;        
          }
        }
  
        > .phones-container {
          > .phone-container {
            > .number {
              @include T3-mobile;
            }
  
            .text {
              @include B-mobile;
            }
          }
        }
      }
  
      #faqs {
        > .left {
          > .title {
            @include T1-mobile;
          }
        }
  
        > .right {
          > .section {
            > .title {  
              > p {
                @include S1-mobile;
              }
            }
  
            > .text {
              > p {
                @include S3-mobile;
              }
            }
          }
  
          > .question {
            @include S2-mobile;
          }
        }
      }
  
      #contacto {
        > .container {
          > .left {
            > .title {
              @include T1-mobile;
            }
  
            > .subtitle {
              @include S2-mobile;
            }
          }
  
          > .right {
            > form {
              > label {
                @include S2-mobile;

                > input,
                > textarea {
                  @include S3-mobile;
                }
              }
  
              > button {
                @include B-mobile;
              }
            }
          }
        }
      }
    }
  
    .footer {
      > .content {
        > .info {
          flex-direction: column;

          > .left {
            > .container {
              > .title {
                @include S2-mobile;
              }
  
              > .item {
                @include L1-mobile;
              }
            }
          }
  
          > .right {
            padding-top: 50px;
            text-align: left;

            > .title {
              @include S2-mobile;
            }
  
            > .link {
              justify-content: flex-start;

              > svg {
                width: 18px;
                height: 18px;
              }

              > p {
                @include L1-mobile;
              }
            }
          }
        }
      }
  
      > .copyright {
        > p {
          @include L2-mobile;         
        }
      }
    }
  }
}

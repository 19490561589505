@import './Fonts.scss';

* {
  font-family: 'museo-sans';
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

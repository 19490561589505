@mixin L1-mobile {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

@mixin L2-mobile {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

@mixin L3-mobile {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

@mixin T1-mobile {
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

@mixin T2-mobile {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

@mixin T3-mobile {
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
}

@mixin S1-mobile {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

@mixin S2-mobile {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

@mixin S3-mobile {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@mixin B-mobile {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

@mixin L1-tablet {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

@mixin L2-tablet {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

@mixin T1-tablet {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

@mixin T2-tablet {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
}

@mixin T3-tablet {
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
}

@mixin S1-tablet {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

@mixin S2-tablet {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

@mixin S3-tablet {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@mixin B-tablet {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.not-found-main {
  height: 100vh;
  width: 100vw;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 45vw;
    position: absolute;
  }

  .top-wing {
    top: 0;
    left: 2vw;
  }

  .bot-wing {
    bottom: 0;
    right: 2vw;
  }

  .content {
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 5;

    h1 {
      font-size: 8vw;
      font-weight: 900;
      background: -webkit-linear-gradient(#4577FB, #28C3FF);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 0 1vw;
    }
  
    h2 {
      font-size: 1.8vw;
      font-weight: 500;
      color: #4577FB;
      margin: 0;
    }
  
    p {
      font-size: 1.2vw;
      font-weight: 300;
      color: #7E7E7E;
      margin: 2vw 0;
    }
  
    button {
      width: 15vw;
      height: 2vw;
      background-color: #4577FB;
      color: #FFFFFF;
      border: none;
      border-radius: 0.5vw;
      font-size: 1vw;
      margin: 0;
      cursor: pointer;
    }
  }
}
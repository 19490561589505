@mixin L1-web {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

@mixin L2-web {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

@mixin L3-web {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@mixin T1-web {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

@mixin T2-web {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
}

@mixin T3-web {
  font-size: 25px;
  font-weight: 700;
  line-height: 48px;
}

@mixin S1-web {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

@mixin S2-web {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

@mixin S3-web {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

@mixin B-web {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
